<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import { ProcessLog } from '@/found/modules';
import FormPage from '../form/form.vue';

export default {
  extends: TablePage,
  components: {
    // Modal,
    FormPage,
    ProcessLog,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('channel-cost-apply');
  },

  methods: {
    clickVisible({ row, val: { code } }) {
      const { approveStatus, processCode } = row;
      // 审批通过 approved,审批中 approving,活动关闭 closed,流程追回 interrupt,驳回 rejected
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === '1' || approveStatus === '2')) return false;
      return true;
    },
    modalClick({ val, row }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        id: '', // 数据code
        formShow: true,
      };
      if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      } else {
        switch (val.code) {
          case 'add':
            formPageArg.drawerTitle = '新增';
            break;
          case 'view':
            formPageArg.drawerTitle = '查看';
            formPageArg.id = row.id;
            break;
          case 'edit':
            formPageArg.drawerTitle = '编辑';
            formPageArg.id = row.id;
            break;
          default:
            formPageArg.drawerTitle = '新增';
        }
        this.createFormPage(formPageArg);
      }
    },
    createFormPage(arg) {
      const that = this;
      const Component = Vue.extend(FormPage);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('channel-cost-apply');
          },
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
